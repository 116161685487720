<template>
  <div class="aiRecord">
    <div class="navBar">
      <div @click="$router.go(-1)">
        <svg-icon class="leftBtn" iconClass="back"></svg-icon>
      </div>
      <div class="title">生成记录</div>
      <div class="rightBtn"></div>
    </div>
    <van-tabs v-model="activeIndex" @change="changeTab" background="#15151500" title-active-color="#ffffff"
      title-inactive-color="white" class="vant_tabs" animated :swipeable="true">
      <van-tab title="排队中" key="0">
        <InProgress @previewBigImg="previewBigImg" />
      </van-tab>
      <van-tab title="生成成功" key="1">
        <Generated @previewBigImg="previewBigImg" />
      </van-tab>
      <van-tab title="生成失败" key="2">
        <GenerateFail @previewBigImg="previewBigImg" />
      </van-tab>
    </van-tabs>
    <van-popup v-model="showBigImg" class="bigImg" v-if="bigImgUrl">
      <ImgDecypt :src="bigImgUrl" class="bigImg" :key="bigImgUrl" @click.native="showBigImg = false"/>
    </van-popup>
  </div>
</template>
<script>
import { Tab, Tabs } from "vant";
import ImgDecypt from "@/components/ImgDecypt";
import InProgress from "./InProgress";
import Generated from "./Generated";
import GenerateFail from "./GenerateFail";

export default {
  name: 'aiRecord',
  components: {
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    ImgDecypt,
    InProgress,
    Generated,
    GenerateFail,
  },
  data() {
    return {
      activeIndex: 0,
      showBigImg: false,
      bigImgUrl: '', // 大图地址
    }
  },
  methods: {
    // 切换tab
    changeTab(name) {
      this.$router.replace(name);
    },
    previewBigImg(url) {
      this.bigImgUrl = url;
      this.showBigImg = true;
    }
  }
}
</script>
<style lang="scss" scoped>
.aiRecord {
  height: 100%;
  .navBar {
    height: 44px;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    box-sizing: border-box;
    color: white;
    background-color: #f21313;
    .leftBtn {
      width: 22px;
      height: 22px;
    }
    .title {
      font-size: 20px;
    }
    .rightBtn {
      width: 22px;
      height: 22px;
      font-size: 14px;
      color: rgb(218,218,218);
    }
  }
}
/deep/ .vant_tabs {
  .van-tabs__wrap {
    padding: 0 20px;
    .van-tabs__nav {
      .van-tab {
        background-color: rgb(36,36,36);
        border-radius: 2px;
        font-size: 16px;
        color: rgb(136,136,136);
        width: 80px !important;
        height: 30px;
        margin: 0 15px;
      }
      .van-tab--active {
        background-color: rgb(238,44,61);
        font-size: 16px;
        color: white;
      }
      .van-tabs__line {
        display: none;
      }
    }
  }
}
.bigImg {
  height: 100vh;
  width: 100vw;
}
/deep/ .bigImg img {
  object-fit: contain !important;
}
</style>