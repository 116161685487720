<template>
  <div class="generateFail">
    <div class="header">
      <div class="idTitle">生成ID</div>
      <div class="coverTitle">原图</div>
      <div class="remarkTitle">失败原因</div>
      <div class="timeTitle">生成时间</div>
    </div>
    <div class="main">
      <PullRefresh
        @onLoad="onLoad"
        :loading="loading"
        :finished="finished"
        @onRefresh="onRefresh"
        :refreshing="refreshing"
        :firstLoading="firstLoading"
        :isNoData="isNoData"
        :isHigehtMax="true"
      >
        <div class="resultBox" v-for="item in list" :key="item.id">
          <div class="id">{{ item.id }}</div>
          <div class="cover" @click="previewBigImg(item.originPic)">
            <ImgDecypt :src="item.originPic" class="cover" :key="item.originPic"/>
          </div>
          <div class="remark">{{ item.remark }}</div>
          <div class="time">{{ item.updatedAt | date }}</div>
        </div>
      </PullRefresh>
      <div class="sizeBox"></div>
    </div>
  </div>
</template>
<script>
import PullRefresh from "@/views/widget/PullRefresh.vue";
import { getAiRecord } from "@/api/loufeng";
export default {
  name: "generateFail",
  components: {
    PullRefresh,
  },
  data() {
    return {
      list: [],
      pageNumber: 1, // 页码
      pageSize: 15, // 条数
      loading: true, // loading
      refreshing: false, // 刷新状态
      finished: false, // 下拉状态
      error: false, // 是否显示错误信息
      isNoData: false, // 暂无数据
      firstLoading: true, //是否第一次加载
    };
  },
  created() {
    this.getList();
  },
  methods: {
    // 查询列表
    async getList() {
      let req = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        status: 3,
      };
      try {
        let res = await this.$Api(getAiRecord, req);
        this.loading = false;
        this.refreshing = false;
        this.firstLoading = false;
        if (res && res.code == 200) {
          let list = res.data.list ? res.data.list : [];
          this.total = res.data.count;
          this.list = this.list.concat(list);
          if (list.length == 0 && this.pageNumber == 1) {
            this.isNoData = true;
            return;
          }
          if (list.length < this.pageSize) {
            this.finished = true;
          }
        }
      } catch (error) {
        this.loading = false;
        this.error = true;
      }
    },
    // 上拉加载
    onLoad() {
      this.pageNumber++;
      this.getList();
    },
    // 下拉刷新
    onRefresh() {
      this.pageNumber = 1;
      this.refreshing = true;
      this.finished = false;
      this.isNoData = false;
      this.list = [];
      this.getList();
    },
    previewBigImg(imgUrl) {
      this.$emit("previewBigImg", imgUrl);
    },
  },
};
</script>

<style lang="scss" scoped>
.generateFail {
  height: calc(100vh - 96px);
  // overflow-y: auto;
  // overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  .header {
    height: 30px;
    margin-bottom: 12px;
    font-size: 14px;
    color: rgb(48, 45, 45);
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 17px;
    text-align: center;
    .idTitle {
      width: 100px;
    }
    .coverTitle {
      width: 60px;
    }
    .remarkTitle {
      width: 90px;
    }
    .timeTitle {
      width: 100px;
    }
  }
  .main {
    padding: 0 12px;
    height: calc(100% - 42px);
  }
  .resultBox {
    margin-bottom: 12px;
    font-size: 14px;
    color: rgb(153, 153, 153);
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0px;
    text-align: center;
    .id {
      width: 100px;
      word-break: break-all;
    }
    .cover {
      width: 29px;
      height: 35.9px;
    }
    .remark {
      width: 90px;
      word-break: break-all;
    }
    .time  {
      width: 100px;
    }
  }
}
.sizeBox {
  height: 40px;
}

/deep/ .noData {
  height: calc(100% - 60px);
}
</style>
